import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import MUICheckbox from '@material-ui/core/Checkbox'
import LabelWithAsterisk from './LabelWithAsterisk'

import {} from '../../actions'

const styles = theme => ({
  root: {
    margin: '0 0 8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0 .75rem'
  },
  rootInGroup: {
    margin: '0 0 .5rem',
  },
  label: {
    color: theme.palette.common.black,
    fontSize: '1rem',
    marginRight: 0
  },
  labelInGroup: {
    fontWeight: 400,
    fontSize: '0.7544642857142857rem'
  },
  controlLabel: {
    marginRight: 0
  },
  switchClass: {
    padding: '.25rem',
  }
})

class Checkbox extends React.Component {
  state = {}

  componentDidMount = () => {
    const { field } = this.props

    this.setState({
      currentValue: field.value
    })
  }

  onChange = (event) => {
    const { field, onChange } = this.props

    const value = event.target.checked

    this.setState({
      currentValue: value
    })

    return onChange({type: 'checkbox'}, field, value)
  }

  onClick = () => {
    const { field, onChange } = this.props

    const { currentValue } = this.state

    return onChange({type: 'checkbox', inLabel: true}, field, currentValue)
  }

  getClass = (className) => {
    const { classes, customClasses, inGroup } = this.props

    let toReturn = classes[className]

    if (customClasses) {
      toReturn += ' ' + customClasses[className]
    }

    if (inGroup) {
      toReturn += ' ' + classes[className + 'InGroup']
    }

    return toReturn
  }

  componentDidUpdate = (prevProp) => {
    const { currentValue } = this.state

    const { value: prevValue } = prevProp.field

    const { field, onChange } = this.props

    const { value, updateRelated } = field

    if (prevValue !== value && currentValue !== value) {
      this.setState({
        currentValue: value
      })

      if (updateRelated && updateRelated.recursive) { // recursive = calls onChange after an updateRelated
        return onChange({type: 'checkbox'}, field, value)
      }
    }
  }

  render() {
    const { field, customClasses, classes, readonly } = this.props

    const { currentValue } = this.state

    const fieldReadonly = readonly || field.readonly

    return (
      <FormGroup className={this.getClass('root')}>
        <FormControlLabel
          className={
            this.getClass('controlLabel')
          }
          control={
            <MUICheckbox
              checked={currentValue ? true : false}
              onChange={this.onChange}
              disabled={fieldReadonly}
              color='primary'
              classes={customClasses && customClasses.switch ? customClasses.switch.classes : {
                root: classes.switchClass
              }}
            />
          } />
        <FormLabel className={this.getClass('label')} onClick={this.onClick}><LabelWithAsterisk>{field.label}</LabelWithAsterisk></FormLabel>
      </FormGroup>
    )
  }
}

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Checkbox)))
